@media (max-width: 1200px) {
  .hero-slider-controls-wrap {
    width: 75%;
  }
  .hero-tabs-control {
    margin-left: 0;
    margin-right: 0;
  }
  .hero-tab-btn-wrap {
    padding: 0 15px;
  }
}
@media (max-width: 992px) {
  .section-hero {
    padding-top: 150px;

    &__title {
      font-size: 60px;
    }
    &__subtitle {
      font-size: 24px;
    }
  }

  .hero-slider-controls-wrap {
    width: 100%;
  }

  .kids-amount-wrap {
    margin-bottom: 30px;
  }
  .price-card {
    transform: none;
  }
  .radio-row {
    margin-bottom: 30px;
  }
}
// @media (max-width: 768px) {
@media (max-width: 796px) {
  .main-footer {
    text-align: center;
  }
  .hello-player-wrap {
    height: 420px;
  }
  .section {
    padding-top: 20px;
    padding-bottom: 20px;
    &--extra-top {
      padding-top: 40px;
    }

    &-title {
      font-size: 30px;
    }
  }
  .section-hero {
    padding-top: 150px;
    padding-bottom: 70px;
    &__title {
      text-align: center;
      font-size: 40px;
    }
    &__subtitle {
      text-align: center;
      font-size: 18px;
      font-weight: normal;

      width: 80%;
      margin: auto;
    }
    &__splash-wrap {
      transform: none;
      margin-bottom: 50px;
    }
  }

  .hero-tabs-container {
    margin-top: 50px;
  }
  .hero-slider-controls-wrap {
    width: 80%;
  }
  .hero-slider-controls {
    margin-left: auto;
  }

  .arrow-left {
    transform: scale(0.8);
  }
  .arrow-right {
    transform: scale(0.8);
  }

  .hero-image-slider {
    &__image {
      width: 150px;
    }
  }
  .hero-slider {
    &__inner {
      padding-left: 182px;
    }
    &__text {
      margin-bottom: 30px;
    }
  }

  .section-hero-price {
    padding-top: 150px;
  }
  .c-price {
    justify-content: center;
  }
  .price-title {
    text-align: center;
    font-size: 30px;
  }
  .radio-row {
    justify-content: center;
  }
  .price-card {
    margin-top: 40px;
    margin-bottom: 60px;
  }
  .mobile-price-footer {
    display: flex;
  }
  .mobile-price-footer-logo {
    display: flex;
  }
}
// @media (max-width: 576px) {
@media (max-width: 596px) {
  .hello-player-wrap {
    height: 360px;
  }

  .hero-tabs-control {
    margin-left: 0;
    margin-right: 0;
  }
  .hero-tab-btn-wrap {
    padding: 0 15px;
  }

  .hero-slider-controls-wrap {
    padding: 0 50px;
    padding-bottom: 30px;
  }
  .hero-slider-controls {
    margin: auto;
    margin-left: auto;
  }

  .hero-slider-wrap {
    position: relative;
    max-width: 800px;
    margin: auto;
    padding-top: 60px;
  }
  .hero-image-slider {
    display: none;
  }
  .hero-slider {
    &__inner {
      text-align: center;
      padding: 0 30px;
    }

    &__step-title {
      font-size: 30px;
    }
    &__title {
      font-size: 40px;
    }
    &__text {
      font-size: 15px;
      margin-bottom: 80px;
    }
  }
  .radio-row {
    margin-left: -10px;
    margin-right: -10px;
  }

  .mobile-price-footer {
    background-repeat: no-repeat;
    background-size: cover;
  }
}
@media (max-width: 440px) {
  .hero-slider-controls-wrap {
    padding: 0 7.5px;
    padding-bottom: 30px;
  }
  .hero-slider-controls {
    margin: auto;
    margin-left: auto;
    padding-top: 6px;
  }

  .hero-slider-wrap {
    position: relative;
    max-width: 800px;
    margin: auto;
    padding-top: 60px;
  }
  .hero-image-slider {
    display: none;
  }
  .hero-slider {
    &__inner {
      text-align: center;
      padding: 0 30px;
    }

    &__step-title {
      font-size: 30px;
    }
    &__title {
      font-size: 40px;
    }
    &__text {
      font-size: 15px;
      margin-bottom: 80px;
    }
  }
}
@media (max-width: 360px) {
  .c-price {
    &__title {
      font-size: 90px;
      line-height: 90px;
    }
    &__subtitle {
      font-size: 18px;
    }

    &__value {
      font-size: 260px;
      line-height: 172px;
    }
  }

  .price-card {
    &__submit {
      min-width: 0;
    }
  }
  .radio-set {
    &__label {
      font-size: 18px;
    }
    &__checkmark {
      top: 0;
    }
  }
}
@media (max-width: 330px) {
  .c-price {
    &__title {
      font-size: 70px;
      line-height: 70px;
    }
    &__subtitle {
      font-size: 14px;
    }

    &__value {
      font-size: 220px;
      line-height: 172px;
    }
  }
}

@media (min-width: 576px) {
  .w-sm-auto {
    width: auto !important;
  }
}
